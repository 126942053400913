<template>
  <div class="container">
    <div class="personage-nav">
      <div class="container-fluid">
        <div class="flex j-w">
          <!-- 头像 -->
          <div class="flex">
            <div
              class="bg"
              :style="{
                backgroundImage: 'url(' + webImgUrls + ')',
                backgroundRepeat: 'no-repeat',
              }"
            ></div>
            <div class="f-left">
              <h1>你好！{{ username }}</h1>
              <p class="text">{{ school }}</p>
              <div
                class="flex cor conet personage-btn j-c"
                @click="installingManagingIncident"
              >
                信息管理
                <div class="cor-rig"></div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="f-rig">
              <div class="flex conet" style="marginleft: 18px">
                <span>已选课程：</span>
                <span class="bold">{{ courseNumber }}</span>
              </div>
              <div class="flex top conet">
                <span>已完成课程：</span>
                <span class="bold">{{ performNumber }}</span>
              </div>
            </div>
            <div class="f-rig-total">
              <div class="flex conet" style="marginleft: 36px">
                <span>总学时：</span>
                <span class="bold">{{ allStudyHour }}</span>
              </div>
              <div class="flex top conet">
                <span>已获得学时：</span>
                <span class="bold">{{ getStudyHour }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCreditStatistics } from '@/api/api';
import { mapGetters } from 'vuex';
export default {
  name: 'PersonageNav',
  props: {
    personageTime: {
      type: Number,
      default: new Date().getFullYear()
    }
  },
  data() {
    return {
      allStudyHour: '',
      courseNumber: '',
      getStudyHour: '',
      performNumber: ''
    };
  },
  watch: {
    personageTime() {
      this.creditStatistics();
    }
  },
  computed: {
    ...mapGetters(['username', 'webImgUrls', 'school'])
  },
  created() {
    setTimeout(() => {
      this.creditStatistics();
    }, 20);
  },
  methods: {
    // 设置管理
    installingManagingIncident() {
      if (this.$route.path != '/manage') {
        this.$router.push({
          path: '/manage'
        });
      }
    },
    // allStudyHour   总学时 ,
    // courseNumber   已选课程数 ,
    // getStudyHour   已完获取学时 ,
    // performNumber  已完成课程数
    creditStatistics() {
      const jsonData = {
        year: this.personageTime
      };
      getCreditStatistics(jsonData).then((res) => {
        if (res.code == 200) {
          const {
            allStudyHour,
            courseNumber,
            getStudyHour,
            performNumber
          } = res.data;
          this.allStudyHour = allStudyHour;
          this.courseNumber = courseNumber;
          this.getStudyHour = getStudyHour;
          this.performNumber = performNumber;
        }
      });
    }
  }
};
</script>
