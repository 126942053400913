var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"learn-table-be container"},[_c('div',{staticClass:"container-fluid"},[(_vm.list.length > 0)?[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.list}},[_c('el-table-column',{attrs:{"label":"课程培训","width":"280"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:[
                'tilte',
                scope.row.activityState != 'PUBLISHED' ? 'bgs' : '' ],on:{"click":function($event){$event.stopPropagation();return _vm.continueLearningIncident(scope.row)}}},[_vm._v(" "+_vm._s(scope.row.activityName)+" ")])]}}],null,false,3731280959)}),_c('el-table-column',{attrs:{"label":"性质","align":"center","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("course_classification")(scope.row.tag)))])]}}],null,false,2881052226)}),_c('el-table-column',{attrs:{"label":"学习进度","align":"center","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.isNeedStudyHour)?[(
                  !isNaN(
                    (scope.row.toLearnStudyHour / scope.row.allStudyHour) *
                      100
                  )
                )?_c('div',[_c('el-progress',{attrs:{"percentage":_vm.conversionPlastic(
                      (scope.row.toLearnStudyHour / scope.row.allStudyHour) *
                        100
                    )}})],1):_c('div',[_c('el-progress',{attrs:{"percentage":0}})],1)]:[(
                  !isNaN(
                    (scope.row.learnedCourseNum / scope.row.allCourseNum) *
                      100
                  )
                )?_c('div',[_c('el-progress',{attrs:{"percentage":_vm.conversionPlastic(
                      (scope.row.learnedCourseNum / scope.row.allCourseNum) *
                        100
                    )}})],1):_c('div',[_c('el-progress',{attrs:{"percentage":0}})],1)]]}}],null,false,3239278979)}),_c('el-table-column',{attrs:{"label":"学时","align":"center","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.isNeedStudyHour)?_c('span',{staticClass:"text"},[_vm._v(_vm._s(scope.row.toLearnStudyHour)+"/"+_vm._s(scope.row.allStudyHour))]):_c('span',{staticClass:"text"},[_vm._v(_vm._s(scope.row.learnedCourseNum)+"/"+_vm._s(scope.row.allCourseNum))])]}}],null,false,3457142329)}),_c('el-table-column',{attrs:{"label":"报名时间","align":"center","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("timeFormatter")(scope.row.entryTime,"{year}年{month}月{day}日")))])]}}],null,false,4222232800)}),_c('el-table-column',{attrs:{"label":"开课时间","align":"center","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [[(
                  scope.row.nextCourseStartTime === null ||
                  scope.row.nextCourseEndTime === null
                )?_c('span',{staticClass:"text"},[_vm._v("--")]):_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.dataNextTime( scope.row.nextCourseStartTime, scope.row.nextCourseEndTime )))])]]}}],null,false,351779570)}),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"flex j-c conet"},[(
                  scope.row.activityState == 'PUBLISHED' &&
                  scope.row.tag != 'ONSITE_TEACHING'
                )?_c('el-button',{class:['tiltes'],attrs:{"type":"text","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.continueLearningIncident(scope.row)}}},[_vm._v(" "+_vm._s(scope.row.toLearnStudyHour / scope.row.allStudyHour == 0 ? "开始学习" : "继续学习")+" ")]):(
                  scope.row.activityState == 'CANNELED' &&
                  scope.row.tag != 'ONSITE_TEACHING'
                )?_c('span',{staticClass:"bgs"},[_vm._v("已下架")]):(scope.row.tag != 'ONSITE_TEACHING')?_c('span',{staticClass:"bgs",staticStyle:{"marginleft":"18px"}},[_vm._v("—")]):(scope.row.tag == 'ONSITE_TEACHING')?_c('el-button',{class:['tiltes'],attrs:{"type":"text","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.continueLearningIncident(scope.row)}}},[_vm._v("查看详情")]):_vm._e(),_c('el-button',{staticClass:"tiltes",attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.examineSignPopupIncident(scope.row)}}},[_vm._v(" 查看报名回执 ")]),(scope.row.activityState != 'PUBLISHED')?_c('el-button',{staticStyle:{"color":"#e4393c"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.deleteIncident(scope.row)}}},[_vm._v(" 删除 ")]):_vm._e()],1)]}}],null,false,1357329055)})],1),(_vm.currentTotal > 10)?_c('div',{staticClass:"page"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.currentTotal},on:{"current-change":_vm.currentChange}})],1):_vm._e()]:[_c('AbsolutelyEmpty',{attrs:{"title":"暂无相关课程！","img":_vm.signTsleImg}})]],2),_c('ExamineSignPopup',{attrs:{"dialogVisible":_vm.dialogVisible,"endRegisterTime":_vm.endRegisterTime,"courseStartTime":_vm.courseStartTime,"activityName":_vm.activityName,"tag":_vm.tag,"examineObj":_vm.examineObj},on:{"beforeClose":function () {
        _vm.dialogVisible = false;
      },"sendEmail":_vm.sendEmail}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }