import { render, staticRenderFns } from "./learn-table-be.vue?vue&type=template&id=f312c906&scoped=true&"
import script from "./learn-table-be.vue?vue&type=script&lang=js&"
export * from "./learn-table-be.vue?vue&type=script&lang=js&"
import style0 from "./learn-table-be.vue?vue&type=style&index=0&id=f312c906&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f312c906",
  null
  
)

export default component.exports