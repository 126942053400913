<template>
  <div class="learn-table-be container">
    <div class="container-fluid">
      <template v-if="list.length > 0">
        <el-table :data="list" style="width: 100%" v-loading="loading">
          <el-table-column label="课程培训" width="280">
            <template slot-scope="scope">
              <span
                :class="[
                  'tilte',
                  scope.row.activityState != 'PUBLISHED' ? 'bgs' : '',
                ]"
                @click.stop="continueLearningIncident(scope.row)"
              >
                {{ scope.row.activityName }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="性质" align="center" width="90">
            <template slot-scope="scope">
              <span class="text">{{
                scope.row.tag | course_classification
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="学习进度" align="center" width="200">
            <template slot-scope="scope">
              <template v-if="scope.row.isNeedStudyHour">
                <div
                  v-if="
                    !isNaN(
                      (scope.row.toLearnStudyHour / scope.row.allStudyHour) *
                        100
                    )
                  "
                >
                  <el-progress
                    :percentage="
                      conversionPlastic(
                        (scope.row.toLearnStudyHour / scope.row.allStudyHour) *
                          100
                      )
                    "
                  ></el-progress>
                </div>
                <div v-else>
                  <el-progress :percentage="0"></el-progress>
                </div>
              </template>
              <template v-else>
                <div
                  v-if="
                    !isNaN(
                      (scope.row.learnedCourseNum / scope.row.allCourseNum) *
                        100
                    )
                  "
                >
                  <el-progress
                    :percentage="
                      conversionPlastic(
                        (scope.row.learnedCourseNum / scope.row.allCourseNum) *
                          100
                      )
                    "
                  ></el-progress>
                </div>
                <div v-else>
                  <el-progress :percentage="0"></el-progress>
                </div>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="学时" align="center" width="90">
            <template slot-scope="scope">
              <span class="text" v-if="scope.row.isNeedStudyHour"
                >{{ scope.row.toLearnStudyHour }}/{{
                  scope.row.allStudyHour
                }}</span
              >
              <span class="text" v-else
                >{{ scope.row.learnedCourseNum }}/{{
                  scope.row.allCourseNum
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="报名时间" align="center" width="160">
            <template slot-scope="scope">
              <span class="text">{{
                scope.row.entryTime | timeFormatter("{year}年{month}月{day}日")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="开课时间" align="center" width="140">
            <template slot-scope="scope">
              <template>
                <span
                  v-if="
                    scope.row.nextCourseStartTime === null ||
                    scope.row.nextCourseEndTime === null
                  "
                  class="text"
                  >--</span
                >
                <span v-else class="text">{{
                  dataNextTime(
                    scope.row.nextCourseStartTime,
                    scope.row.nextCourseEndTime
                  )
                }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="220">
            <template slot-scope="scope">
              <div class="flex j-c conet">
                <el-button
                  v-if="
                    scope.row.activityState == 'PUBLISHED' &&
                    scope.row.tag != 'ONSITE_TEACHING'
                  "
                  type="text"
                  :class="['tiltes']"
                  size="small"
                  @click.stop="continueLearningIncident(scope.row)"
                >
                  {{
                    scope.row.toLearnStudyHour / scope.row.allStudyHour == 0
                      ? "开始学习"
                      : "继续学习"
                  }}
                </el-button>
                <span
                  class="bgs"
                  v-else-if="
                    scope.row.activityState == 'CANNELED' &&
                    scope.row.tag != 'ONSITE_TEACHING'
                  "
                  >已下架</span
                >
                <span
                  class="bgs"
                  v-else-if="scope.row.tag != 'ONSITE_TEACHING'"
                  style="marginleft: 18px"
                  >—</span
                >
                <el-button
                  type="text"
                  :class="['tiltes']"
                  @click.stop="continueLearningIncident(scope.row)"
                  size="small"
                  v-else-if="scope.row.tag == 'ONSITE_TEACHING'"
                  >查看详情</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  class="tiltes"
                  @click="examineSignPopupIncident(scope.row)"
                >
                  查看报名回执
                </el-button>
                <el-button
                  v-if="scope.row.activityState != 'PUBLISHED'"
                  type="text"
                  size="small"
                  style="color:#e4393c"
                  @click="deleteIncident(scope.row)"
                >
                  删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page" v-if="currentTotal > 10">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="currentChange"
            :total="currentTotal"
          >
          </el-pagination>
        </div>
      </template>
      <template v-else>
        <AbsolutelyEmpty
          title="暂无相关课程！"
          :img="signTsleImg"
        ></AbsolutelyEmpty>
      </template>
    </div>
    <ExamineSignPopup
      :dialogVisible="dialogVisible"
      :endRegisterTime="endRegisterTime"
      :courseStartTime="courseStartTime"
      :activityName="activityName"
      :tag="tag"
      :examineObj="examineObj"
      @beforeClose="
        () => {
          dialogVisible = false;
        }
      "
      @sendEmail="sendEmail"
    >
    </ExamineSignPopup>
  </div>
</template>
<script>
import Utils from '@/utils';
import {
  myCourse,
  examineMyCourseRegisterInfo,
  myCourseSendEmail,
  deleteRegisterInfo
} from '@/api/learn';
import { course_classification } from '@/config/statusCode';
import ExamineSignPopup from './examine-sign-popup';
import AbsolutelyEmpty from '@/components/AbsolutelyEmpty/index';
import signTsleImg from '@/assets/images/personage/sho-chang.png';
import { timeFormatter } from '@/utils/verify';
import list from '@/mixins/list';
export default {
  name: 'LearnTableBe',
  filters: {
    course_classification,
    timeFormatter
  },
  mixins: [list],
  components: {
    ExamineSignPopup,
    AbsolutelyEmpty
  },
  props: {
    time: {
      type: String,
      default: new Date().getFullYear()
    }
  },
  watch: {
    time(time) {
      var substringTime = time.substring(0, 4);
      this.getMyCourse(substringTime);
    }
  },
  data() {
    return {
      dialogVisible: false,
      endRegisterTime: '',
      courseStartTime: '',
      tag: '',
      activityName: '',
      examineObj: {},
      signTsleImg: signTsleImg,
      activityId: ''
    };
  },
  methods: {
    // 时间计算
    conversionPlastic(num) {
      return parseFloat(num.toFixed(2));
    },
    dataNextTime(start, end) {
      if (new Date(start).getDate() === new Date(end).getDate()) {
        return (
          timeFormatter(start, '{year}年{month}月{day}日 {hour}:{minute}') +
          '~' +
          timeFormatter(end, '{hour}:{minute}')
        );
      }
      return (
        timeFormatter(start, '{year}年{month}月{day}日 {hour}:{minute}') +
        '~' +
        timeFormatter(end, '{day}日 {hour}:{minute}')
      );
    },
    getMyCourse(time) {
      this.loading = true;
      const jsonData = {
        state: 'undone',
        time: time || new Date().getFullYear()
      };
      const param = this.getParams(jsonData);
      myCourse(param).then((res) => {
        if (res.code == 200) {
          const { total, list } = res.data;
          this.currentTotal = total;
          this.list = list;
          this.loading = false;
          this.$store.commit('setTotal', total);
        }
      });
    },
    loadData() {
      var substringTime = this.time.substring(0, 4);
      this.getMyCourse(substringTime);
    },
    continueLearningIncident(row) {
      if (row.activityState != 'PUBLISHED') {
        Utils.msgWarning('该课程已下架');
        return;
      }
      this.$router.push({
        path: '/courseDetails',
        query: {
          id: row.id
        }
      });
    },
    examineSignPopupIncident(row) {
      this.activityName = row.activityName;
      this.endRegisterTime = timeFormatter(
        row.endRegisterTime,
        '{year}年{month}月{day}日 {hour}:{minute}:{second}'
      );
      this.courseStartTime = timeFormatter(
        row.entryTime,
        '{year}年{month}月{day}日 {hour}:{minute}:{second}'
      );
      this.tag = row.tag;
      this.activityId = row.id;
      examineMyCourseRegisterInfo({ activityId: row.id }).then((res) => {
        if (res.code == 200) {
          this.dialogVisible = true;
          this.examineObj = res.data;
        }
      });
    },
    // 删除
    deleteIncident({ id, isNeedStudyHour, toLearnStudyHour, learnedCourseNum }) {
      const _this = this
      const obj = {
        btnText: '知道了'
      };
      if (isNeedStudyHour) {
        if (toLearnStudyHour > 0) {
          _this.$periodPopup({
            title: '抱歉，该课程关系到您已获取到的学时，已无法删除',
            ...obj
          }).then(() => {});
          return
        }
      } else {
        if (learnedCourseNum > 0) {
          _this.$periodPopup({
            title: '抱歉，该课程关系到您已获取到的学时，已无法删除',
            ...obj
          }).then(() => {});
          return
        }
      }
      _this.$trainPopup({
        title: '确定删除该已下架课程？',
        leftBtn: '取消',
        rightBtn: '确认'
      }).then((res) => {
        if (res.code == 200) {
          deleteRegisterInfo({
            activityId: id
          }).then((r) => {
            if (r.code == 200) {
              Utils.msgSuccess('删除成功', 3000);
              var substringTime = this.time.substring(0, 4);
              this.getMyCourse(substringTime);
            } else {
              _this.$periodPopup({
                title: r.msg,
                ...obj
              }).then(() => {});
            }
          });
        }
      })
    },
    // 发送邮箱
    sendEmail(email) {
      myCourseSendEmail({
        activityId: this.activityId,
        emailStr: email
      }).then((res) => {
        if (res.code == 200) {
          Utils.msgSuccess('发送邮箱成功，请注意查收', 3000);
          setTimeout(() => {
            this.dialogVisible = false;
          }, 800);
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.learn-table-be {
  min-height: 500px;
  font-weight: 400 !important;
  .tilte {
    .fontSize(@TEXT-SIZE-14);
    color: @TEXT-COLOR-363636;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .comment();
  .tiltes {
    color: #5090ff !important;
    font-size: 14px;
  }
  .tiltes:hover {
    text-decoration: underline;
  }
  .bgs {
    color: #e6e6e6 !important;
  }
}
/deep/.el-progress,
/deep/.el-progress--line {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  width: 100%;
  justify-content: space-between;
}
/deep/.el-progress-bar {
  padding-right: 0px !important;
  width: 67% !important;
}
/deep/.el-progress__text {
  width: 33% !important;
  margin-left: 0;
}
/deep/.el-progress-bar__outer {
  width: 100% !important;
  height: 8px !important;
}
/deep/.el-progress-bar__inner {
  background-color: @TEXT-BG-FF5102;
}
</style>
