<template>
  <div v-if="dialogVisible">
    <el-dialog
      :visible.sync="dialogVisible"
      width="530px"
      :before-close="beforeClose"
    >
      <div class="dialog-delete" @click="deleteClose">
        <i class="el-icon-close"></i>
      </div>
      <div class="examine-sign-popup">
        <div class="title">{{ activityName }}</div>
        <div class="time" v-if="tag == 'LIVE_BROADCAST'">
          开课时间： {{ courseStartTime }}
        </div>
        <div class="time" v-else>报名截止时间：{{ endRegisterTime }}</div>
        <div class="up">
          <div class="flex conet upBox">
            <div class="left">姓名：</div>
            <span>{{ examineObj.baseDto.name }}</span>
          </div>
          <div class="flex conet upBox">
            <div class="left">手机号：</div>
            <span>{{ examineObj.baseDto.teacherPhone }}</span>
          </div>
          <div class="flex conet upBox">
            <div class="left">地区：</div>
            <span
              >{{ examineObj.baseDto.province }}/{{
                examineObj.baseDto.city
              }}/{{ examineObj.baseDto.area }}</span
            >
          </div>
          <div class="flex conet upBox">
            <div class="left">工作单位：</div>
            <span>{{ examineObj.baseDto.school }}</span>
          </div>
          <div v-for="(itme, index) in examineObj.answerDtos" :key="index">
            <div class="flex conet upBox" style="margin-bottom: 15px">
              <div class="left">
                <span v-if="itme.isRequire" style="color: #e4393c">*</span>
                {{ itme.title }}：
              </div>
              <template v-if="itme.type == 'SELECT'">
                <div v-for="(s, i) in itme.options" :key="i">
                  <span v-if="itme.answer == s.optionId">{{
                    s.optionName
                  }}</span>
                </div>
              </template>
              <template v-if="itme.type == 'TEXT'">
                <span>{{ itme.answer }}</span>
              </template>
            </div>
          </div>
        </div>
        <div
          v-if="tag == 'LIVE_BROADCAST'"
          :class="['inform', examineObj.baseDto.isNotify ? 'active' : '']"
        >
          开课前40分钟接收短信通知
        </div>
        <div class="affirm">
          <input type="email" v-model="email" placeholder="请输入邮箱号码" />
          <el-button
            :class="[email.length > 0 ? 'active' : '']"
            @click="sendEmail"
          >
            发送邮箱
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { checkEmail } from '@/utils/verify';
import Utils from '@/utils/index';
export default {
  name: 'ExamineSignPopup',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    endRegisterTime: {
      type: String,
      default: ''
    },
    courseStartTime: {
      type: String,
      default: ''
    },
    tag: {
      type: String,
      default: ''
    },
    examineObj: {
      type: Object,
      default: {}
    },
    activityName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      email: ''
    };
  },
  watch: {
    dialogVisible(vol) {
      if (vol) {
        this.email = '';
      }
    }
  },
  methods: {
    beforeClose() {},
    deleteClose() {
      this.email = '';
      this.$emit('beforeClose', {});
    },
    sendEmail() {
      if (!checkEmail(this.email)) {
        Utils.msgWarning('邮箱不正确', 3000);
        return;
      }
      this.$emit('sendEmail', this.email);
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
@import "~./popup.less";
/deep/.el-dialog {
  border-radius: 10px;
  height: auto;
  overflow: hidden;
}
/deep/.el-dialog__headerbtn {
  display: none;
}
.examine-sign-popup {
  box-sizing: border-box;
  padding-bottom: 40px;
  padding-right: 40px;
  padding-left: 40px;
  .title,
  .time {
    text-align: center;
    line-height: 24px;
  }
  .title {
    .fontSize(@TEXT-SIZE-18);
    font-weight: bold;
    margin-bottom: 10px;
  }
  .time {
    color: @TEXT-COLOR-FF5102;
    .fontSize(@TEXT-SIZE-14);
    padding-bottom: 20px;
    .border-direction(bottom,1px,solid,@TEXT-BG-E6E6E6);
    margin-bottom: 20px;
    box-sizing: border-box;
  }
  // 个人信息
  .up {
    box-sizing: border-box;
    padding-bottom: 10px;
    margin-bottom: 20px;
    .upBox {
      width: 100%;
      height: 40px;
      color: @TEXT-COLOR-888888;
      .fontSize(@TEXT-SIZE-14);
    }
    .left {
      width: 120px;
      text-align: right;
      color: @TEXT-COLOR-363636;
      .fontSize(@TEXT-SIZE-16);
      margin-right: 10px;
    }
  }
  // 是否通知
  .inform {
    padding-left: 30px;
    box-sizing: border-box;
    line-height: 20px;
    background: url("~@/assets/images/curriculum/xz.png") no-repeat 0px 0px;
    margin-bottom: 18px;
    .fontSize(@TEXT-SIZE-14);
    &.active {
      background: url("~@/assets/images/curriculum/xz (1).png") no-repeat 0px
        0px;
    }
  }
  .hint {
    .fontSize(@TEXT-SIZE-12);
    color: @TEXT-COLOR-888888;
    line-height: 23px;
  }
  .affirm {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 30px;
    input {
      flex: 1;
      height: 40px;
      border: 1px solid #dddddd;
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 10px;
      border-radius: 8px;
    }
    /deep/.el-button {
      width: 110px;
      height: 40px;
      border-radius: 20px;
      background: #dddddd;
      border: 1px solid #dddddd;
      .fontSize(@TEXT-SIZE-16);
      color: @TEXT-COLOR-FFFFFF !important;
      &.active {
        background: @TEXT-BG-00BD92;
        border: 1px solid @TEXT-BG-00BD92;
      }
    }
  }
}
.dialog-delete {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  .el-icon-close {
    font-size: 20px;
  }
  .el-icon-close:hover {
    color: #00bd92;
  }
}
</style>