<template>
  <div>
    <PersonageNav :personageTime="personageTime"></PersonageNav>
    <LearnBtn
      @detaIncident="detaIncident"
      @changeIncident="changeIncident"
      :personageTime="personageTime"
      :detaIndex="studyIndex"
    >
    </LearnBtn>
    <LearnTableBe v-if="studyIndex == 0" :time="time"> </LearnTableBe>
    <LearnTable v-if="studyIndex == 1" :time="timeUndone"> </LearnTable>
  </div>
</template>
<script>
import LearnBtn from './components/learn-btn';
import LearnTable from './components/learn-table';
import LearnTableBe from './components/learn-table-be';
import PersonageNav from './../personage/components/personage-nav';
export default {
  name: 'Learn',
  components: {
    PersonageNav,
    LearnBtn,
    LearnTable,
    LearnTableBe
  },
  data() {
    return {
      studyIndex: -1,
      timeUndone: '',
      time: '',
      personageTime: new Date().getFullYear()
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const { type } = this.$route.query;
      if (type) {
        this.studyIndex = parseInt(type);
      } else {
        this.studyIndex = 0;
      }
    },
    detaIncident(index) {
      index == 0
        ? (this.time =
            String(this.personageTime) +
            Math.ceil(Math.random(10000000) * 99999999))
        : (this.timeUndone =
            String(this.personageTime) +
            Math.ceil(Math.random(10000000) * 99999999));
      this.studyIndex = index;
      const { type } = this.$route.query;
      this.replaceRouter(index, type);
    },
    changeIncident(e) {
      this.personageTime = parseInt(e);
      this.studyIndex == 0
        ? (this.time = String(e) + Math.ceil(Math.random(10000000) * 99999999))
        : (this.timeUndone =
            String(e) + Math.ceil(Math.random(10000000) * 99999999));
    },
    replaceRouter(date, parameter) {
      if (parameter == date) {
        return;
      }
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, type: date }
      });
    }
  }
};
</script>
