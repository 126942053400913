<template>
  <div class="learn-btn container">
    <div class="container-fluid">
      <TabBox
        :detaIndex="detaIndex"
        :detaList="detaList"
        @detaIncident="detaIncident"
      >
      </TabBox>
      <div class="learn-box">
        <div class="learn-left">
          共 <span>{{ total }}</span> 门课程
        </div>
        <div class="learn-rig">
          <div class="text">年度：</div>
          <el-select
            v-model="value"
            placeholder="请选择"
            @change="changeIncident"
          >
            <el-option
              style="color: #363636; font-weight: normal"
              v-for="item in optionsList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TabBox from '@/components/TabBox/index';
import { mapGetters } from 'vuex';
const dateTime = new Date().getFullYear();
export default {
  name: 'LearnBtn',
  props: {
    detaIndex: {
      type: Number,
      default: 0
    },
    personageTime: {
      type: Number,
      default: dateTime
    }
  },
  components: {
    TabBox
  },
  data() {
    return {
      detaList: ['正在学习课程', '已完成课程'],
      value: dateTime
    };
  },
  watch: {
    detaIndex() {
      this.value = this.personageTime;
    }
  },
  computed: {
    ...mapGetters(['total']),
    optionsList() {
      const arr = [];
      for (let i = 0; i <= 5; i++) {
        var obj = {
          value: dateTime - i,
          label: dateTime - i
        };
        arr.push(obj);
      }
      return arr;
    }
  },
  methods: {
    detaIncident(index) {
      this.$emit('detaIncident', index);
    },
    changeIncident(e) {
      this.$emit('changeIncident', e);
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.learn-btn {
  box-sizing: border-box;
  padding-top: 40px;
  padding-bottom: 25px;
  @import "~@/assets/style/learn";
}
</style>
