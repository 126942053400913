<template>
  <div class="learn-table container">
    <div class="container-fluid">
      <template v-if="list.length > 0">
        <el-table
          :data="list"
          style="width: 100%"
          max-height="660"
          v-loading="loading"
        >
          <el-table-column prop="name" label="课程培训" width="420">
            <template slot-scope="scope">
              <span
                :class="[
                  'tilte',
                  scope.row.activityState != 'PUBLISHED' ? 'bgs' : '',
                ]"
                @click.stop="continueLearningIncident(scope.row)"
              >
                {{ scope.row.activityName }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="性质" align="center" width="120">
            <template slot-scope="scope">
              <span class="text">{{
                scope.row.tag | course_classification
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="已获学时" align="center" width="120">
            <template slot-scope="scope">
              <span class="text">{{ scope.row.toLearnStudyHour }}</span>
            </template>
          </el-table-column>
          <el-table-column label="报名时间" align="center" width="130">
            <template slot-scope="scope">
              <span class="text">{{
                scope.row.entryTime | timeFormatter("{year}年{month}月{day}日")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="完成时间" align="center" width="130">
            <template slot-scope="scope">
              <span class="text" v-if="scope.row.studyHourTime">{{
                scope.row.studyHourTime
                  | timeFormatter("{year}年{month}月{day}日")
              }}</span>
              <span class="text" v-else>—</span>
            </template>
          </el-table-column>
          <el-table-column label="活动证书" align="center" width="120">
            <template>
              <span
                class="text"
                style="cursor: pointer"
                @click="certificateActivityIncident"
                >暂无证书</span
              >
            </template>
          </el-table-column>
          <el-table-column label="评价" align="center" width="140">
            <template slot-scope="scope">
              <div class="flex conet j-c">
                <el-button
                v-if="
                  scope.row.activityScoring == null
                "
                type="text"
                size="small"
                @click.stop="evaluateIncident(scope.row, 'entrance')"
              >
                进入评价
              </el-button>
              <el-button
                v-else-if="
                  scope.row.activityScoring != null
                "
                type="text"
                size="small"
                @click.stop="evaluateIncident(scope.row, 'examine')"
              >
                查看评价
              </el-button>
              <!-- <div v-else class="text" style="fontsize: 14px">活动已下架</div> -->
              <el-button
                  v-if="scope.row.activityState != 'PUBLISHED'"
                  type="text"
                  size="small"
                  class="tiltes"
                  style="color:#e4393c;margin-left:15px"
                  @click="deleteIncident(scope.row)"
                >
                  删除
              </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page" v-if="currentTotal > 10">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="currentChange"
            :total="currentTotal"
          >
          </el-pagination>
        </div>
      </template>
      <template v-else>
        <AbsolutelyEmpty
          title="暂无相关课程！"
          :img="signTsleImg"
        ></AbsolutelyEmpty>
      </template>
    </div>
    <template v-if="dialogVisible">
      <PopupTable
        ref="modal"
        :dialogVisible="dialogVisible"
        @beforeClose="
          () => {
            dialogVisible = true;
          }
        "
        @beforeDelete="
          () => {
            dialogVisible = false;
          }
        "
        :manipulable="manipulable"
        :activityId="activityId"
        :activityName="activityName"
        :questionPaperId="questionPaperId"
        @submitIncident="submitIncident"
      >
      </PopupTable>
    </template>
  </div>
</template>
<script>
import Utils from '@/utils';
import { myCourse, postMyCourseEvaluation, deleteRegisterInfo } from '@/api/learn';
import PopupTable from './popup-table';
import AbsolutelyEmpty from '@/components/AbsolutelyEmpty/index';
import signTsleImg from '@/assets/images/personage/sho-chang.png';
import { course_classification } from '@/config/statusCode';
import { timeFormatter } from '@/utils/verify';
import list from '@/mixins/list';
export default {
  name: 'LearnTable',
  filters: {
    course_classification,
    timeFormatter
  },
  mixins: [list],
  components: {
    PopupTable,
    AbsolutelyEmpty
  },
  props: {
    time: {
      type: String,
      default: new Date().getFullYear()
    }
  },
  data() {
    return {
      dialogVisible: false,
      manipulable: false,
      activityName: '',
      activityId: '',
      whetherQuestionnaire: false, // 无问卷的情况
      signTsleImg: signTsleImg,
      questionPaperId: ''
    };
  },
  watch: {
    time(time) {
      var substringTime = time.substring(0, 4);
      this.getMyCourse(substringTime);
    }
  },
  methods: {
    getMyCourse(time) {
      this.loading = true;
      const jsonData = {
        state: 'done',
        time: time || new Date().getFullYear()
      };
      const param = this.getParams(jsonData);
      myCourse(param).then((res) => {
        if (res.code == 200) {
          const { total, list } = res.data;
          this.currentTotal = total;
          this.list = list;
          this.loading = false;
          this.$store.commit('setTotal', total);
        }
      });
    },
    // 分页切换请求后台
    loadData() {
      var substringTime = this.time.substring(0, 4);
      this.getMyCourse(substringTime);
    },
    // 1、entrance 进入  2、examine 查看
    evaluateIncident(row, method) {
      if (method == 'entrance') {
        this.manipulable = false;
      } else {
        this.manipulable = true;
      }
      this.activityName = row.activityName;
      row.questionPaperId == null
        ? (this.questionPaperId = '')
        : (this.questionPaperId = String(row.questionPaperId));
      this.activityId = String(row.id);
      this.dialogVisible = true;
    },
    // 删除
    deleteIncident({ id, toLearnStudyHour }) {
      const _this = this
      const obj = {
        btnText: '知道了'
      };
      if (toLearnStudyHour > 0) {
        _this.$periodPopup({
          title: '抱歉，该课程关系到您已获取到的学时，已无法删除',
          ...obj
        }).then(() => {});
        return
      }
      _this.$trainPopup({
        title: '确定删除该已下架课程？',
        leftBtn: '取消',
        rightBtn: '确认'
      }).then((res) => {
        if (res.code == 200) {
          deleteRegisterInfo({
            activityId: id
          }).then((r) => {
            if (r.code == 200) {
              Utils.msgSuccess('删除成功', 3000);
              var substringTime = this.time.substring(0, 4);
              this.getMyCourse(substringTime);
            } else {
              _this.$periodPopup({
                title: r.msg,
                ...obj
              }).then(() => {});
            }
          });
        }
      })
    },
    // 提交评价
    submitIncident(e) {
      postMyCourseEvaluation(e)
        .then((res) => {
          if (res.code == 200) {
            var substringTime = this.time.substring(0, 4);
            this.getMyCourse(substringTime);
            const obj = {
              title:
                '问卷已提交，课件将于课程结束后1周内发放，请及时留意短信通知。',
              btnText: '知道了'
            };
            this.manipulable = true;
            this.$periodPopup(obj).then(() => {
              this.dialogVisible = false;
            });
          }
          this.$refs.modal.loadingIncident();
        })
        .catch(() => {
          this.$refs.modal.loadingIncident();
        });
    },
    // 继续学习
    continueLearningIncident(row) {
      if (row.activityState != 'PUBLISHED') {
        Utils.msgWarning('该课程已下架');
        return;
      }
      this.$router.push({
        path: '/courseDetails',
        query: {
          id: row.id
        }
      });
    },
    // 查看证书
    certificateActivityIncident() {}
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.learn-table {
  min-height: 450px;
  font-weight: 400 !important;
  .tilte {
    .fontSize(@TEXT-SIZE-14);
    color: @TEXT-COLOR-363636;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .text {
    .fontSize(@TEXT-SIZE-14);
    color: @TEXT-COLOR-888888;
  }
  .comment();
  /deep/.el-table th,
  .el-table tr {
    background: #ffffff !important;
  }
  .bgs {
    color: #e6e6e6 !important;
  }
}
</style>
